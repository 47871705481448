import axios from "axios";
import {SERVER_URL, DEVELOPMENT_SERVER_URL} from '../../config/server'

import {Notification, Message} from "element-ui";
import router from "../../router";

export function request(config) {
    const instance = axios.create({
        baseURL:
            process.env.NODE_ENV === "development"
                ? DEVELOPMENT_SERVER_URL
                : SERVER_URL,
        timeout: 600 * 1000,
    });
    

    instance.interceptors.request.use(
        (config) => {
            // let authInfo = localStorage.getItem("authInfo");
            // if (authInfo != null) {
            //   authInfo = JSON.parse(authInfo);
            //   config.headers.token = authInfo.data.token;
            // }
            let adminToken = localStorage.getItem("admin-token");
            if (adminToken) {
                config.headers.Authorization = adminToken;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            if (res.data.code === 401 && res.data.message === "暂未登录或token已经过期") {
                // 未登录
                Notification({
                    message: "暂未登录或token已经过期，请登录",
                    duration: 1500,
                    onClose() {
                        window.localStorage.clear();
                        router.replace("/admin/login");
                    },
                });
                return res;
            }
            return res;
        },
        (error) => {
            Message.closeAll();
            // if (error.response.status === 401) {
            //   // 未登录
            //   Notification({
            //     message: "身份验证失败，请登录",
            //     duration: 1500,
            //     onClose() {
            //       console.log('error');
            //       router.push("/admin/login");
            //     },
            //   });
            //   return false;
            // }
            return Promise.reject(error);
        }
    );

    return instance(config);
}

/**
 * axios:upload 文件上传方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function upload(url, data = {}) {
    //  console.log(11111)
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                // console.log(url)
                // console.log(11111)
                resolve(response);
            },
            (err) => {
                // console.log(11111)
                reject(err);
            }
        );
    });
}

// 合并多个请求
export function allRequest(arrRequest) {
    console.log(arrRequest,'222222')
    return axios.all(arrRequest);
}
