import {request, allRequest} from "./network";


export function getSubjectList(params) {
    return request({
        method: 'POST',
        url: 'subject/getAllForExamId',
        params
    })
}

export function getExamList(pageNum, pageSize) {
    return request({
        method: 'GET',
        url: `exam/listAll?pageNum=${pageNum} && pageSize=${pageSize}`
    })
}

export function getGroupList(params) {
    return request({
        method: 'GET',
        url: 'video/group/list',
        params
    })
}

export function getGroupStudent(params) {
    return request({
        method: 'GET',
        url: 'video/group/student/list',
        params
    })
}

export function getVideoList(params) {
    return request({
        method: 'GET',
        url: 'video/list',
        params
    })
}

/*2022-03-29修改*/

export function getExamListInfo(params) {
    return request({
        method: 'GET',
        url: 'exam/newExamList',
        params
    })
}

export function getSubjectListInfo(params) {
    return request({
        method: 'GET',
        url: 'exam/newSubjectList',
        params
    })
}

export function getNewGroupList(params) {
    return request({
        method: 'GET',
        url: 'exam/group/list',
        params
    })
}

export function getNewZkzNameList(params) {
    return request({
        method: 'GET',
        url: 'exam/zkzName/list',
        params
    })
}

export function getAiImagesList(params) {
    return request({
        method: 'GET',
        url: 'exam/images/list',
        params
    })
}

export function getVideoFrontList(params) {
    return request({
        method: 'GET',
        url: 'exam/videoFront/list',
        params
    })
}

export function getVideoBackList(params) {
    return request({
        method: 'GET',
        url: 'exam/videoBack/list',
        params
    })
}