<template>
  <el-container>
    <el-header style="display: flex; align-items: center">
      <div style="display: flex; justify-content: flex-start; cursor: default">
        <span @click="$router.push('/admin/videorecord/lists')">全部考试 > </span
        ><span @click="$router.back()">{{ examName }} > </span
      ><span style="color: #563279">{{ subjectName }}</span>
      </div>
    </el-header>
    <el-main style="padding-top: 5px">
      <div
          style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
        "
      >
        <div
            style="color: #333333; font-size: 20px; font-weight: bold; flex: 1"
        >
          {{ subjectName }}
        </div>
        <div style="flex: 2"></div>
      </div>
      <el-table
          :data="groupList"
          stripe
          style="width: 100%; margin-top: 10px; overflow: auto"
      >
        <el-table-column
            label="全选"
            type="selection"
            width="55"
            align="center"
        >
        </el-table-column>
        <el-table-column label="监控名称">
          <template slot-scope="scope">
            <img
                src="@/assets/image/file.png"
                @click="gotostudentvideo(scope.row.id, scope.row.name)"
            />
            <span style="margin-left: 10px">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
          style="margin-top: 10px"
          :background="true"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import {getNewGroupList} from "r/admin/videorecord";

export default {
  data() {
    return {
      groupList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      examName: "",
      subjectName: "",
      subjectUuid: ""
    };
  },
  created() {
    this.examName = window.localStorage.getItem("examName");
    this.subjectUuid = window.localStorage.getItem("subjectUuid");
    this.subjectName = window.localStorage.getItem("subjectName");
    // if (this.$route.query.subjectName) {
    //   this.subjectName = this.$route.query.subjectName;
    //   this.subjectUuid = this.$route.query.subjectUuid;
    //   window.localStorage.setItem("subjectName", this.subjectName)
    //   window.localStorage.setItem("subjectUuid", this.subjectUuid)
    // } else if (window.localStorage.getItem("subjectName")) {
    //   this.subjectName = window.localStorage.getItem("subjectName");
    // }
    this.getgrouplist();
  },
  methods: {
    //获取监控组列表数据
    async getgrouplist() {
      const {data: res} = await getNewGroupList({
        subjectUuid: this.subjectUuid,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      });
      if (res.code != 200) {
        this.$message({
          message: res.code,
          type: "error",
        });
      }
      if (res.code == 200) {
        this.groupList = res.data.list;
        this.total = res.data.total;
      }
    },
    gotostudentvideo(id, name) {
      window.localStorage.setItem("groupId",id)
      window.localStorage.setItem("groupName",name)
      this.$router.push({
        name: "name-and-zkzNum",
        query: {
          groupId: id,
          groupName: name,
          subjectUuid: this.subjectUuid
        },
      });
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getgrouplist();
    },
    handleCurrentChange(newNum) {
      this.pageNum = newNum;
      this.getgrouplist();
    },
  },
};
</script>

<style scoped>
/* 去除表格的内边框 */
::v-deep .el-table__row > td {
  border: none;
}

/* 去除最下面的边框线 */
::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner:hover {
  border: none;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #563279;
  border-radius: 40px;
}

::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 40px;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #dcdfe6;
  border-color: #fff;
}

::v-deep .el-checkbox__inner {
  border: none;
  background: #dcdfe6;
}

::v-deep .el-input__inner {
  border: none;
  background: #fafafa;
}
</style>